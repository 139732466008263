import React, { PureComponent } from 'react'; 
import axios from 'axios';
import { BASE_PATH } from '../util/constants';
import Header from '../components/Header';
import Footer from '../components/Footer';
import ProjectHero from '../components/ProjectHero';
import ProjectInfo from '../components/ProjectInfo';
import ClientLogo from '../components/ClientLogo';
import Gallery from '../components/Gallery';

class Project extends PureComponent {
    constructor(props, context) {
        super(props, context);

        this.state = {
            project: {},
            client: {},
            images: [],
            isPageAnimating: true,
            vimeoId: '',
        }
    }

    async componentDidMount() {
        const { search } = this.props.location;
        const id = search.split('?id=')[1];
        const { data: project } = await axios.get(`${BASE_PATH}/project/${id}?_embed`);

        if (!project) {
            window.location.href.replace(window.location.origin);
        }

        this.setState({ project });

        await Promise.all([
            this.getClient(),
            this.getImages(),
        ]);

        this.getVimeoUrl();
        this.setState({
            isPageAnimating: false,
        });
    }

    async getClient() {
        const { project } = this.state;

        const clientId = project && project.meta
            ? project.meta['_client_id']
            : '';

        if (!clientId) {
            return;
        }

        const { data: client } = await axios.get(`${BASE_PATH}/client/${clientId}?_embed`);
        this.setState({ client });
    }

    async getImages() {
        const { project } = this.state;

        const mediaIds = project && project.meta
            ? project.meta.project_gallery.split(',')
            : [];
        const allMedia = [];

        if (!mediaIds.length) {
            return;
        }

        await Promise.all(mediaIds.map(async (mediaId) => {
            const { data: media } = await axios.get(`${BASE_PATH}/media/${mediaId}?_embed`);
            allMedia.push(media);
        }));

        this.setState({ images: allMedia });
    }

    getVimeoUrl() {
        const { project } = this.state;

        const vimeoUrl = project && project.meta
            ? project.meta['vimeo_url']
            : '';

        if (!vimeoUrl.length) {
            return;
        }

        const vimeoId = vimeoUrl.substring(vimeoUrl.lastIndexOf('/') + 1);
        this.setState({ vimeoId });
    }

    hasVideo() {
        const { vimeoId } = this.state;
        return Boolean(vimeoId.length);
    }

    title() {
        const { project } = this.state;
        return project && project.title
            ? project.title.rendered
            : '';
    }

    render() {
        const {
            project,
            client,
            images,
            isPageAnimating,
            vimeoId,
        } = this.state;

        return (
            <div className="page projectDetail">
                <Header />
                <div className={`pageContent ${isPageAnimating ? 'animating' : ''}`}>
                    <div className="container">
                        <ProjectHero project={project} />
                        <div className="section infoSection">
                            <ProjectInfo project={project} />
                            <ClientLogo className="projectClientLogo" client={client} />
                        </div>
                        <div className="section">
                            {this.hasVideo() && (
                                <div className="videoWrap">
                                    <iframe
                                        title={this.title()}
                                        src={`https://player.vimeo.com/video/${vimeoId}?loop=1`}
                                        width="1280"
                                        height="480"
                                        frameBorder="0"
                                        allow="autoplay; fullscreen"
                                        allowFullScreen
                                    />
                                </div>
                            )}
                            <Gallery images={images} />
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        );
    }
}

export default Project;
