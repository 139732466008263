export const BASE_URL = 'https://wordpress.triggermoments.com';
export const BASE_MAIL_URL = 'https://triggermoments.com';
export const BASE_PATH = `${BASE_URL}/wp-json/wp/v2`;

/* SCREEN SIZES */
export const SCREEN_SM = 640;
export const SCREEN_MD = 768;
export const SCREEN_LG = 1024;
export const SCREEN_XL = 1400;

/* PAGE IDS */
export const HOME_PAGE_ID = 2;
export const WORK_PAGE_ID = 7;
export const TEAM_PAGE_ID = 9;
export const COVID_PAGE_ID = 7934;
export const FUEL_PAGE_ID = 8258;
export const PIVOT_PAGE_ID = 8260;
export const QUARANTINE_PAGE_ID = 7932;
export const OPS_PAGE_ID = 8619;
export const PRODUCTIONS_PAGE_ID = 8621;
export const STUDIOS_PAGE_ID = 8623;
export const DRAGNWAGON_PAGE_ID = 9159;
export const HIRING_PAGE_ID = 9530;
export const HIRING_PRODUCTIONS_PAGE_ID = 9650;
export const HIRING_STUDIOS_PAGE_ID = 9652;
export const HIRING_OPS_PAGE_ID = 9654;

/* PAGE IDS TO URLS */
export const pageIdsToUrls = {
    7934: 'covid',
    8258: 'fuel',
    8260: 'pivot',   
    7932: 'quarantine',
};
