import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';

class NavItem extends PureComponent {
    render() {
        const { label, link, activeLink, onClick } = this.props;
        const isActive = activeLink === link;

        return (
            <li className="navItem">
                <Link
                    data-target={this.target}
                    to={link}
                    className={`navLink ${isActive ? 'active' : ''}`}
                    onClick={onClick}
                >
                    <span className="navLinkText">{label}</span>
                </Link>
            </li>
        );
    }
}

export default NavItem;
