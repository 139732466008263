/* eslint-disable indent */
import React, { Component } from 'react';
import axios from 'axios';
import { BASE_PATH, HOME_PAGE_ID } from '../util/constants';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Projects from '../components/Projects';
import HomeHeroVideo from '../components/HomeHeroVideo';
import QuoteSection from '../components/QuoteSection';
import Services from '../components/Services';
import Clients from '../components/Clients';
import Contact from '../components/Contact';
import VideoOverlay from '../components/VideoOverlay';

class Home extends Component {
    /**
     * Shuffles and randomizes the projects for the home page display
     *
     * @param {Array} featuredProjects
     * @return {Array}
     */
    static shuffleProjects(featuredProjects = []) {
        const projects = [...featuredProjects];

        for (let i = projects.length - 1; i > 0; i -= 1) {
            const j = Math.floor(Math.random() * i);
            const temp = projects[i];
            projects[i] = projects[j];
            projects[j] = temp;
        }

        return projects.slice(0, 6);
    }

    constructor(props) {
        super(props);

        this.state = {
            clients: [],
            projects: [],
            quotes: [],
            page: {},
            isOverlayOpen: false,
            isPageAnimating: true,
            screenWidth: 0,
        };
        this.clientRef = React.createRef();
        this.contactRef = React.createRef();
    }

    async componentDidMount() {
        const [
            { data: clients },
            { data: projects },
            { data: page },
        ] = await Promise.all([
            Home.getClients(),
            Home.getProjects(),
            Home.getPage(),
        ]);
        const randomizedProjects = Home.shuffleProjects(projects);
        console.log('page: ', page);
        this.setState({
            clients,
            page,
            projects: randomizedProjects,
            isPageAnimating: false,
        });
        this.setQuotes();
        this.scrollToRefs();
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions.bind(this));
    }

    componentDidUpdate(prevProps) {
        if (prevProps.location.hash !== this.props.location.hash) {
            this.scrollToRefs();
        }
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.escFunction, false);
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    static getProjects() {
        return axios.get(`${BASE_PATH}/project?_embed`, {
            params: {
                page: 1,
                per_page: 50,
                meta_key: 'feature_on_home_page',
                meta_value: 1,
            },
        });
    }

    static getClients() {
        return axios.get(`${BASE_PATH}/client?_embed`, {
            params: {
                page: 1,
                per_page: 50,
                meta_key: 'feature_on_home_page',
                meta_value: 1,
            },
        });
    }

    static getPage() {
        return axios.get(`${BASE_PATH}/pages/${HOME_PAGE_ID}`);
    }

    updateWindowDimensions() {
        this.setState({ 
            screenWidth: window.innerWidth,
        });
    }

    calloutMeta() {
        const { page } = this.state;

        if (!page) {
            return {};
        }

        const { meta } = page;
        return meta || {};
    }

    pageContent() {
        console.log('page state', this.state.page);
        const { content } = this.state.page;

        if (!content) {
            return '';
        }

        return content.rendered || '';
    }

    setQuotes() {
        const { meta } = this.state.page;

        if (!meta) {
            return [];
        }

        const quotes = [];

        if (meta.quote_1 && meta.quote_1[0]) {
            quotes.push(meta.quote_1[0]);
        }

        if (meta.quote_2 && meta.quote_2[0]) {
            quotes.push(meta.quote_2[0]);
        }

        if (meta.quote_3 && meta.quote_3[0]) {
            quotes.push(meta.quote_3[0]);
        }

        if (meta.quote_4 && meta.quote_4[0]) {
            quotes.push(meta.quote_4[0]);
        }

        if (meta.quote_5 && meta.quote_5[0]) {
            quotes.push(meta.quote_5[0]);
        }

        if (meta.quote_6 && meta.quote_6[0]) {
            quotes.push(meta.quote_6[0]);
        }

        this.setState({ quotes });
    }

    scrollToRefs() {
        const { hash } = this.props.location;

        switch (hash) {
        case '#clients':
            window.scrollTo(0, this.clientRef.current.offsetTop - 70);
            break;
        case '#contact':
            window.scrollTo(0, this.contactRef.current.offsetTop - 55);
            break;
        case '':
            window.scrollTo(0, 0);
            break;
        default:
            window.scrollTo(0, 0);
        };
    }

    /**
     * Toggles lightbox open for video
     *
     * @param {Boolean} isOpen
     */
    toggleLightbox(isOpen) {
        this.setState({
            isOverlayOpen: isOpen,
        });

        if (isOpen) {
            document.body.classList.add('overlay-open');
        } else {
            document.body.classList.remove('overlay-open');
        }
    }

    escFunction(event) {
        if (event.keyCode === 27) {
            this.toggleLightbox(false);
        }
    }

    render() {
        const {
            projects,
            clients,
            quotes,
            isOverlayOpen,
            isPageAnimating,
            screenWidth,
        } = this.state;
        const meta = this.calloutMeta();

        console.log('state quotes: ', quotes);

        return (
            <div className="page home">
                <Header />
                <HomeHeroVideo
                    onToggleLightbox={this.toggleLightbox.bind(this)}
                />

                <div className={`pageContent ${isPageAnimating ? 'animating' : ''}`}>
                    {Boolean(quotes.length) && (
                    <div
                        className="section calloutSection"
                        role="article"
                    >
                        <QuoteSection quotes={quotes} />
                    </div>
                    )}

                    {Boolean(projects.length) && (
                    <div
                        className="section featuredProjectsSection"
                        role="article"
                    >
                        <div className="container">
                            <h2 className="homeHeading">Featured Projects</h2>
                            <Projects projects={projects} />
                            <a className="seeMoreLink" href="/our-work">
                                <span>See More</span>
                            </a>
                        </div>
                    </div>
                    )}

                    <div
                        className="section servicesSection"
                        role="article"
                    >
                        <Services callout={meta} />
                    </div>

                    <div
                        ref={this.clientRef}
                        className="section clientSection"
                        role="article"
                    >
                        <Clients
                            clients={clients}
                            screenWidth={screenWidth}
                        />
                    </div>

                    <div
                        ref={this.contactRef}
                        className="section contactSection"
                    >
                        <Contact />
                    </div>
                </div>
                <Footer />

                {isOverlayOpen && (
                    <VideoOverlay
                        isOpen={this.isOverlayOpen}
                        onToggleLightbox={this.toggleLightbox.bind(this)}
                    />
                )}
            </div>
        );
    }
}

export default Home;
