import React, { PureComponent } from 'react';
import Logo from './Logo';
import Nav from './Nav';

class Header extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            isScrolling: false,
        };
        this.headerRef = React.createRef();
        this.handleScroll = this.handleScroll.bind(this);
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll() {
        const { current: header } = this.headerRef;

        if (header.scrollHeight - window.scrollY < 0) {
            this.setState({ isScrolling: true });
            return;
        }

        this.setState({ isScrolling: false });
    }

    render() {
        const { isScrolling } = this.state;

        return (
            <header
                ref={this.headerRef}
                onScroll={this.handleScroll}
                className={`header ${isScrolling ? 'is-scrolling' : ''}`}
            >
                <div className="container headerContainer">
                    <div className="flex items-center justify-between">
                        <Logo />
                        <Nav />
                    </div>
                </div>
            </header>
        );
    }
}

export default Header;
