import React, { PureComponent } from 'react';
import MainCallout from './MainCallout';


class QuoteSection extends PureComponent {
    constructor(props) {
        super(props);

        const { quotes } = this.props;

        this.interval = null;
        this.state = {
            quotes,
            currentQuote: quotes.length
                ? quotes[0]
                : '',
        };
    }

    componentDidMount() {
        this.setCurrentQuote();
    }
  
    setCurrentQuote() {
        const { quotes } = this.state;
        let counter = 0;

        if (!quotes || !quotes.length) {
            return '';
        }

        this.interval = setInterval(() => {
            if (counter === 5 || (quotes.length < 6 && counter === quotes.length - 1)) {
                counter = 0;
            } else {
                counter++;
            }

            this.setState({
                currentQuote: quotes[counter],
            });
        }, 10000);
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    render() {
        const { currentQuote } = this.state;

        return (
            <MainCallout content={currentQuote} isQuoteSection={true} />
        );
    }
}

export default QuoteSection;


