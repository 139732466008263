import React from 'react';
import Helmet from 'react-helmet';
import {
    BrowserRouter as Router,
    Switch,
    Route,
} from 'react-router-dom';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';

import Home from './pages/Home';
import Work from './pages/Work';
import Team from './pages/Team';
import ProjectDetail from './pages/ProjectDetail';
import Page from './pages/Page';
import Hiring from './pages/Hiring';
import {
    COVID_PAGE_ID,
    FUEL_PAGE_ID,
    PIVOT_PAGE_ID,
    QUARANTINE_PAGE_ID,
    PRODUCTIONS_PAGE_ID,
    STUDIOS_PAGE_ID,
    OPS_PAGE_ID,
    DRAGNWAGON_PAGE_ID,
    HIRING_OPS_PAGE_ID,
    HIRING_PRODUCTIONS_PAGE_ID,
    HIRING_STUDIOS_PAGE_ID,
} from './util/constants';

function App() {
    library.add(fab);

    return (
        <Router>
            <Helmet title="Triggerhouse" />
            <div className="wrapper">
                {/*
                A <Switch> looks through all its children <Route>
                elements and renders the first one whose path
                matches the current URL. Use a <Switch> any time
                you have multiple routes, but you want only one
                of them to render at a time
                */}
                <Switch>
                    <Route exact path="/" component={Home} />
                    <Route exact path="/our-work" component={Work} />
                    <Route exact path="/project/:name" render={({ match, location }) =>
                        <ProjectDetail match={match} location={location} />}
                    />
                    <Route exact path="/our-team" component={Team} />
                    <Route exact path="/quarantine" render={() =>
                        <Page pageId={QUARANTINE_PAGE_ID} pageName="Quarantine" />}
                    />
                    <Route exact path="/covid" render={() =>
                        <Page pageId={COVID_PAGE_ID} pageName="Covid" />}
                    />
                    <Route exact path="/pivot" render={() =>
                        <Page pageId={PIVOT_PAGE_ID} pageName="Pivot" />}
                    />
                    <Route exact path="/fuel" render={() =>
                        <Page pageId={FUEL_PAGE_ID} pageName="Fuel" />}
                    />
                    <Route exact path="/ops" render={() =>
                        <Page pageId={OPS_PAGE_ID} pageName="Ops" />}
                    />
                    <Route exact path="/studios" render={() =>
                        <Page pageId={STUDIOS_PAGE_ID} pageName="Studios" />}
                    />
                    <Route exact path="/productions" render={() =>
                        <Page pageId={PRODUCTIONS_PAGE_ID} pageName="Productions" />}
                    />
                    <Route exact path="/dragnwagon" render={() =>
                        <Page pageId={DRAGNWAGON_PAGE_ID} pageName="Drag'n Wagon" />}
                    />
                    <Route exact path="/jobs" component={Hiring} />
                    <Route exact path="/hiring-ops" render={() =>
                        <Page pageId={HIRING_OPS_PAGE_ID} pageName="HiringOps" />}
                    />
                    <Route exact path="/hiring-productions" render={() =>
                        <Page pageId={HIRING_PRODUCTIONS_PAGE_ID} pageName="HiringProductions" />}
                    />
                    <Route exact path="/hiring-studios" render={() =>
                        <Page pageId={HIRING_STUDIOS_PAGE_ID} pageName="HiringStudios" />}
                    />
                </Switch>
            </div>
        </Router>
    );
}

export default App;
