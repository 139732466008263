import React, { PureComponent } from 'react';
import { BASE_URL } from '../util/constants';

class Logo extends PureComponent {
    static imgSrc() {
        return `${BASE_URL}/wp-content/themes/triggerhouse/img/trig_logo.png`;
    }

    render() {
        return (
            <h1>
                <a className="logo" href="/">
                    <span className="hidden">Triggerhouse</span>
                    <img className="logoImg" src={Logo.imgSrc()} alt="Triggerhouse" />
                </a>
            </h1>
        );
    }
}

export default Logo;
