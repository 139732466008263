import React, { PureComponent } from 'react';
import { get, isEmpty } from 'lodash';
import { BASE_URL } from '../util/constants';

class ProjectBlock extends PureComponent {
    state = {
        project: this.props.project,
    }

    /**
     * Get the title for the project
     */
    title() {
        return {__html: this.state.project.title.rendered};
    }

    /**
     * Get the image url for the project
     */
    imageUrl() {
        const sizes = get(this.state, 'project._embedded.wp:featuredmedia.0.media_details.sizes', {});

        if (isEmpty(sizes)) {
            return '';
        }

        if (sizes.medium_large) {
            return sizes.medium_large.source_url;
        } else if (sizes.large) {
            return sizes.large.source_url;
        }

        return sizes.full.source_url;
    }

    projectUrl() {
        const { project } = this.state;
        const { link, id } = project;

        if (!link) {
            return '';
        }

        return this.formatLink(link, id);
    }

    /**
     * Format the link for React router
     *
     * @param {String} link
     * @param {String} id
     * @return {String}
     */
    formatLink(link, id) {
        const newLink = link.split(BASE_URL)[1];
        
        if (!newLink) {
            return '';
        }
        
        const routerLink = newLink.slice(0, -1);
        return `${routerLink}?id=${id}`;
    }

    render() {
        return (
            <a className="projectLink" href={this.projectUrl()}>
                <div className="projectOverlay">
                    <div className="overlayContent">
                        <p
                            className="projectText"
                            dangerouslySetInnerHTML={this.title()}
                        ></p>
                    </div>
                </div>

                <div className="imgWrap">
                    <img
                        className="projectImg" 
                        src={this.imageUrl()}
                        alt=""
                    />
                </div>

                <p
                    className="projectMobileText"
                    dangerouslySetInnerHTML={this.title()}
                ></p>
            </a>
        )
    }

};

export default ProjectBlock;