import React, { PureComponent } from 'react';
import { get } from 'lodash';

class ServicesCallout extends PureComponent {
    calloutHeader(key) {
        const calloutMeta = this.props.callout;
        return calloutMeta[key] || '';
    }

    calloutText(key) {
        const calloutMeta = this.props.callout;
        return { __html: get(calloutMeta[key], '[0]', '') };
    }

    render() {
        return (
            <div className="container">
                <h2 className="homeHeading">What We Do</h2>
                <ul className="flex flex-wrap">
                    <li className="calloutItem triggerStudios">
                        <a href="/studios">
                            <h3>
                                <span>Trigger Studios</span>
                            </h3>
                            <h4>{this.calloutHeader('studios_header')}</h4>
                            <p dangerouslySetInnerHTML={this.calloutText('studios')} />
                        </a>
                    </li>
                    <li className="calloutItem triggerOps">
                        <a href="/ops">
                            <h3>
                                <span>Trigger Ops</span>
                            </h3>
                            <h4>{this.calloutHeader('ops_header')}</h4>
                            <p dangerouslySetInnerHTML={this.calloutText('ops')} />
                        </a>
                    </li>
                    <li className="calloutItem triggerProductions">
                        <a href="/productions">
                            <h3>
                                <span>Trigger Productions</span>
                            </h3>
                            <h4>{this.calloutHeader('productions_header')}</h4>
                            <p dangerouslySetInnerHTML={this.calloutText('productions')} />
                        </a>
                    </li>
                </ul>
            </div>
        );
    }
}

export default ServicesCallout;
