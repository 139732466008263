import React, { PureComponent } from 'react';
import {
    CarouselProvider,
    Slider,
    Slide,
    ButtonBack,
    ButtonNext,
} from 'pure-react-carousel';
import ClientLogo from './ClientLogo';
import { 
    SCREEN_MD,
    SCREEN_LG,
    SCREEN_XL,
} from '../util/constants';
import 'pure-react-carousel/dist/react-carousel.es.css';

class Clients extends PureComponent {
    visibleSlides() {
        const { screenWidth } = this.props;

        if (screenWidth <= SCREEN_MD) {
            return 2;
        } else if ((screenWidth > SCREEN_MD) && (screenWidth < SCREEN_LG)) {
            return 3;
        } else if ((screenWidth >= SCREEN_LG) && (screenWidth < SCREEN_XL)) {
            return 5;
        }

        return 6;
    }

    slideStep() {
        const { screenWidth } = this.props;

        if (screenWidth <= SCREEN_MD) {
            return 2;
        } else if ((screenWidth > SCREEN_MD) && (screenWidth < SCREEN_LG)) {
            return 3;
        }

        return 4;
    }

    slideAriaLabel(idx) {
        return `slide-${idx}`;
    }

    render() {
        const { clients } = this.props;

        return (
            <div className="container">
                <h2 className="homeHeading text-center">The Company We Keep</h2>
                <div className="clients">
                    {Boolean(clients.length) && (
                        <CarouselProvider
                            visibleSlides={this.visibleSlides()}
                            totalSlides={clients.length}
                            step={this.slideStep()}
                            naturalSlideWidth={400}
                            naturalSlideHeight={500}
                        >
                            <ButtonBack className="carouselBtn btnPrev">
                                <span className="btnCaret" />
                            </ButtonBack>
                            <Slider
                                className="slider"
                                role="list"
                            >
                                {clients.map((client, idx) => (
                                    <Slide
                                        key={client.id}
                                        index={idx}
                                        className="slide"
                                        role="listitem"
                                        ariaLabel={this.slideAriaLabel(idx)}
                                    >
                                        <ClientLogo
                                            client={client}
                                        />
                                    </Slide>
                                ))}
                            </Slider>
                            <ButtonNext className="carouselBtn btnNext">
                                <span className="btnCaret" />
                            </ButtonNext>
                        </CarouselProvider>
                    )}
                </div>
            </div>
        );
    }
}

export default Clients;
