import React, { PureComponent } from 'react';

class ProjectInfo extends PureComponent {
    title() {
        return this.props.project && this.props.project.title
            ? { __html: this.props.project.title.rendered }
            : { __html: '' };
    }

    projectMeta() {
        return this.props.project && this.props.project.meta
            ? this.props.project.meta
            : {};
    }

    location() {
        return this.projectMeta().location || '';
    }

    challenge() {
        return this.projectMeta().challenge || '';
    }

    approach() {
        return this.projectMeta().approach || '';
    }

    results() {
        return this.projectMeta().results || '';
    }

    render() {
        return (
            <div className="infoBlockWrap">
                <div className="infoBlock">
                    <h2 dangerouslySetInnerHTML={this.title()} />
                    <h4>{this.location()}</h4>
                </div>

                <div className="infoBlock">
                    <h3>Challenge</h3>
                    <p>{this.challenge()}</p>
                </div>

                <div className="infoBlock">
                    <h3>Approach</h3>
                    <p>{this.approach()}</p>
                </div>

                {this.results() && (
                    <div className="infoBlock infoBlockResults">
                        <h3>Results</h3>
                        <p>{this.results()}</p>
                    </div>
                )}
            </div>
        );
    }
}

export default ProjectInfo;
