import React, { PureComponent } from 'react';

class HeroVideo extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            canPlayVideo: true,
        };
        this.videoTimeout = null;
        this.videoRef = React.createRef();
    }

    componentDidMount() {
        this.videoTimeout = setTimeout(() => {
            if (this.videoRef && this.videoRef.current) {
                this.videoRef.current.play()
                    .catch((e) => {
                        console.log(e);
                        this.setState({ canPlayVideo: false });
                    });
            } else {
                this.setState({ canPlayVideo: false });
            }
        }, 0);
    }

    componentWillUnmount() {
        clearTimeout(this.videoTimeout);
    }

    fallbackImgSrc() {
        return 'https://wordpress.triggermoments.com/wp-content/themes/triggerhouse/img/triggerhouse_banner.jpg';
    }

    render() {
        const { canPlayVideo } = this.state;

        return (
            <div
                className="heroVideo"
                role="banner"
            >
                {canPlayVideo && (
                    <video
                        ref={this.videoRef}
                        loop
                        id="videoBg"
                        className="videoBg"
                        muted
                        onClick={() => this.props.onToggleLightbox(true)}
                    >
                        <source src="https://wordpress.triggermoments.com/wp-content/themes/triggerhouse/src/wearetriggerhouse.mp4" type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                )}
                {!canPlayVideo && (
                    <div className="fallbackImg">
                        <img
                            src={this.fallbackImgSrc()}
                            alt="We Are Triggerhouse"
                            onClick={() => this.props.onToggleLightbox(true)}
                        />
                    </div>
                )}
            </div>
        )
    }
}

export default HeroVideo;
