import React, { PureComponent } from 'react';
import uuid from 'uuid';
import NavItem from './NavItem';

class Nav extends PureComponent {
    navItems = [
        {
            label: 'Home',
            link: '/',
        },
        {
            label: 'Our Work',
            link: '/our-work',
        },
        {
            label: 'Our Team',
            link: '/our-team',
        },
        {
            label: 'Clients',
            link: '/#clients',
        },
        {
            label: 'Jobs',
            link: "/jobs",
        },
        {
            label: 'Contact',
            link: '/#contact',
        },
    ]

    static checkActiveLink(link = '') {
        const { pathname, hash } = window.location;
        const path = `${pathname}${hash}`;
        return link.length ? path === link : path;
    }

    constructor(props) {
        super(props);

        this.state = {
            isMobileNavOpen: false,
            activeLink: Nav.checkActiveLink(),
        };
    }

    toggleMobileNav(bool) {
        if (bool) {
            this.setState({ isMobileNavOpen: true });
            return;
        }

        this.setState({ isMobileNavOpen: false });
    }

    handleLinkClick() {
        const { isMobileNavOpen } = this.state;

        if (isMobileNavOpen) {
            this.toggleMobileNav(false);
        }

        // Not sure why we have to do this, window.location props not updating in time
        this.linkTimeout = setTimeout(() => {
            this.setState({
                activeLink: Nav.checkActiveLink(),
            });
        }, 0);
    }

    componentWillUnmount() {
        clearTimeout(this.linkTimeout);
    }

    render() {
        const { isMobileNavOpen, activeLink } = this.state;

        return (
            <div>
                <nav className="nav hidden lg:block">
                    <ul>
                        {this.navItems.map((item) => {
                            const { link, label } = item;
                            
                            return (
                                <NavItem
                                    key={uuid()}
                                    label={label}
                                    link={link}
                                    activeLink={activeLink}
                                    onClick={this.handleLinkClick.bind(this, link)}
                                />
                            )
                        })}
                    </ul>
                </nav>
                <span
                    className="hamburger block lg:hidden"
                    onClick={() => this.toggleMobileNav(true)}
                >
                    <span className="hamburger__bar" />
                </span>
                <nav className={`navMobile ${isMobileNavOpen ? 'is-open' : ''}`}>
                    <ul>
                        {this.navItems.map((item) => {
                            const { link, label } = item;
                            
                            return (
                                <NavItem
                                    key={uuid()}
                                    label={label}
                                    link={link}
                                    activeLink={activeLink}
                                    onClick={this.handleLinkClick.bind(this, link)}
                                />
                            )
                        })}
                    </ul>
                    <div
                        className="iconClose"
                        onClick={() => this.toggleMobileNav(false)}
                    ></div>
                </nav>
            </div>
        );
    }
}

export default Nav;
