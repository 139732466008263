import { get, isEmpty } from 'lodash';
import React, { Component } from 'react';

class ClientLogo extends Component {
    logoUrl() {
        const { client } = this.props;

        if (isEmpty(client)) {
            return '';
        }

        return get(client, '_embedded.wp:featuredmedia.0.source_url', '');
    }

    title() {
        const { client } = this.props;

        if (!client) {
            return '';
        }

        return (client.title && client.title.rendered) || '';
    }

    render() {
        const logoUrl = this.logoUrl();

        return (
            <div
                className="clientImgWrap flex items-center"
            >
                {logoUrl && (
                    <img
                        className="clientImg"
                        src={this.logoUrl()}
                        alt={this.title()}
                    />
                )}
            </div>
        );
    }
}

export default ClientLogo;
