import React, { Component } from 'react';

class CategoryFilters extends Component {
    state = {
        active: '',
    }

    render() {
        const { categories } = this.props;
        const { active } = this.state;

        return (
            <div className="categoryFiltersWrap">
                {categories.map((category) => {
                    const isActive = active === category.id;

                    return  (
                        <div
                            className={`filter ${isActive ? 'active' : ''}`}
                            key={category.id}
                            onClick={() => this.setActive(category.id)}
                        >
                            <span
                                className="filterText"
                                dangerouslySetInnerHTML={{ __html: category.name }}
                            />
                        </div>
                    );
                })}
            </div>
        )
    }

    setActive(categoryId) {
        this.props.setCategory(categoryId);
        this.setState({ active: categoryId });
    }
}

export default CategoryFilters;
