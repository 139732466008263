import React, { PureComponent } from 'react';
import { get } from 'lodash';
import axios from 'axios';
import Helmet from 'react-helmet';
import { BASE_PATH } from '../util/constants';
import Header from '../components/Header';
import Footer from '../components/Footer';
import PageHeroVideo from '../components/PageHeroVideo';

class Page extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            pageData: {},
            isPageAnimating: true,
        };
    }

    async componentDidMount() {
        const { data: pageData } = await this.getPageData();

        this.setState({
            pageData,
            isPageAnimating: false,
        });
    }

    getPageData() {
        const { pageId } = this.props; 
        return axios.get(`${BASE_PATH}/pages/${pageId}?_embed`);
    }

    featuredImageUrl() {
        const { featured_img_url: featuredImageUrl } = this.state.pageData;
        return featuredImageUrl;
    }

    heroVideoUrl() {
        const { hero_video_url: heroVideoUrl } = this.state.pageData;
        return heroVideoUrl;
    }

    departmentLogoUrl() {
        const { department_logo_url: departmentLogoUrl } = this.state.pageData;
        return departmentLogoUrl;
    }

    departmentTagline() {
        return get(this, 'state.pageData.meta.department_tagline.0', '');
    }

    pageContent() {
        const { pageData } = this.state;
        const { content } = pageData;
        return content
            ? { __html: content.rendered }
            : { __html: '' };
    }

    render() {
        const {
            isPageAnimating,
        } = this.state;
        const { pageName } = this.props;
        const pageClass = pageName.toLowerCase();
        const heroVideoUrl = this.heroVideoUrl();
        const featuredImageUrl = this.featuredImageUrl();
        const departmentLogoUrl = this.departmentLogoUrl();
        const departmentTagline = this.departmentTagline();

        return (
            <div className={`page page--template ${pageClass}`}>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Triggerhouse | {pageName}</title>
                    <link rel="canonical" href={`https://triggerhouse.com/${pageClass}`} />
                </Helmet>
                <Header />
                <div className={`pageContent ${isPageAnimating ? 'animating' : ''}`}>
                    <div className="section container">
                        {departmentLogoUrl && (
                            <div className="departmentLogoWrap">
                                <img
                                    src={departmentLogoUrl}
                                    alt=""
                                />
                            </div>
                        )}
                        {departmentTagline && (
                            <h2 className="departmentTagline">{departmentTagline}</h2>
                        )}
                        {heroVideoUrl && (
                            <PageHeroVideo
                                videoUrl={heroVideoUrl}
                                featuredImageUrl={featuredImageUrl}
                            />
                        )}
                        {(featuredImageUrl && !heroVideoUrl) && (
                            <div className="heroImgWrap">
                                <img
                                    src={featuredImageUrl}
                                    alt=""
                                />
                            </div>
                        )}
                        <div dangerouslySetInnerHTML={this.pageContent()} />
                    </div>
                </div>
                <Footer />
            </div>
        );
    }
}

export default Page;
