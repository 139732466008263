import React, { Component } from 'react';
import { get, isEmpty } from 'lodash';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app

export default class Gallery extends Component {
    constructor(props) {
        super(props);

        this.state = {
            photoIndex: 0,
            isLightboxOpen: false,
        };
    }

    getLightboxImages() {
        const images = this.projectImages();

        return images.map(image => image.media_details.sizes.full.source_url);
    }

    /**
     * Get the image url for the project
     *
     * @param {Object} image
     */
    static getImageUrl(image) {
        const sizes = get(image, 'media_details.sizes', {});

        if (isEmpty(sizes)) {
            return '';
        }

        if (sizes.large) {
            return sizes.large.source_url;
        } if (sizes.medium_large) {
            return sizes.medium_large.source_url;
        } if (sizes.medium) {
            return sizes.medium.source_url;
        }

        return sizes.full.source_url;
    }

    /**
     * Filter out images with no resizing
     */
    projectImages() {
        const { images } = this.props;

        if (!images.length) {
            return [];
        }

        return images.reduce((acc, image) => {
            const { sizes } = image.media_details;
            if (!isEmpty(sizes)) {
                acc.push(image);
            }
            return acc;
        }, []);
    }


    /**
     * Toggles lightbox open and sets gallery index
     *
     * @param {Boolean} isOpen
     * @param {Number} idx
     */
    toggleLightbox(isOpen, idx) {
        this.setState({
            isLightboxOpen: isOpen,
            photoIndex: idx,
        });
    }

    render() {
        const { photoIndex, isLightboxOpen } = this.state;
        const images = this.projectImages();
        const lightboxImages = this.getLightboxImages();

        return (
            <div className="gallery">
                {images.map((image, idx) => (
                    <div
                        key={image.id}
                        className="galleryImgWrap"
                        onClick={() => this.toggleLightbox(true, idx)}
                    >
                        <img
                            className="galleryImg"
                            src={Gallery.getImageUrl(image)}
                            alt={image.alt_text}
                        />
                    </div>
                ))}

                {isLightboxOpen && (
                    <Lightbox
                        mainSrc={lightboxImages[photoIndex]}
                        nextSrc={lightboxImages[(photoIndex + 1) % lightboxImages.length]}
                        prevSrc={lightboxImages[(photoIndex + lightboxImages.length - 1) % lightboxImages.length]}
                        onCloseRequest={() => this.toggleLightbox(false, 0)}
                        onMovePrevRequest={() => this.setState({
                            photoIndex: (photoIndex + lightboxImages.length - 1) % lightboxImages.length,
                        })}
                        onMoveNextRequest={() => this.setState({
                            photoIndex: (photoIndex + 1) % lightboxImages.length,
                        })}
                    />
                )}
            </div>
        );
    }
}
