import React, { PureComponent } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class Footer extends PureComponent {
    static handleIconClick(linkType) {
        switch (linkType) {
        case 'facebook':
            window.open('https://www.facebook.com/triggerhouse/', '_blank');
            break;
        case 'instagram':
            window.open('https://www.instagram.com/triggerhouse/', '_blank');
            break;
        case 'vimeo':
            window.open('https://vimeo.com/triggerhouse/', '_blank');
            break;
        case 'twitter':
            window.open('https://twitter.com/triggerhouse/', '_blank');
            break;
        case 'linkedin':
            window.open('https://www.linkedin.com/company/trigger-house/', '_blank');
            break;
        default:
            break;
        }
    }

    render() {
        const currentYear = new Date().getFullYear();

        return (
            <footer className="footer">
                <div className="container justify-content flex-between">
                    <span className="copyright">
                        &copy; {currentYear} Trigger(House
                    </span>
                    <div className="socialIcons">
                        <FontAwesomeIcon
                            className="icon"
                            icon={['fab', 'facebook-f']}
                            onClick={() => Footer.handleIconClick('facebook')}
                        />
                        <FontAwesomeIcon
                            className="icon"
                            icon={['fab', 'instagram']}
                            onClick={() => Footer.handleIconClick('instagram')}
                        />
                        <FontAwesomeIcon
                            className="icon"
                            icon={['fab', 'vimeo-v']}
                            onClick={() => Footer.handleIconClick('vimeo')}
                        />
                        <FontAwesomeIcon
                            className="icon"
                            icon={['fab', 'twitter']}
                            onClick={() => Footer.handleIconClick('twitter')}    
                        />
                        <FontAwesomeIcon
                            className="icon"
                            icon={['fab', 'linkedin-in']}
                            onClick={() => Footer.handleIconClick('linkedin')}
                        />
                    </div>
                    <div className="emailLink">
                        <a href="mailto:info@triggerhouse.com">
                            <span>info@triggerhouse.com</span>
                        </a>
                    </div>
                </div>
            </footer>
        );
    }
}

export default Footer;
