import React, { PureComponent } from 'react';
import ProjectBlock from './ProjectBlock';

class Projects extends PureComponent {
    render() {
        const { projects } = this.props;

        return (
            <div className="projects">
                {projects.map((project) => <ProjectBlock key={project.id} project={project} />)}
            </div>
        );
    }
}

export default Projects;
