import React, { PureComponent } from 'react'; 
import axios from 'axios';
import { BASE_PATH, TEAM_PAGE_ID } from '../util/constants';
import Header from '../components/Header';
import Footer from '../components/Footer';
import TeamMember from '../components/TeamMember';
import MainCallout from '../components/MainCallout';

class Team extends PureComponent {
    constructor(props) {
        super(props);

        document.title = 'Triggerhouse | Our Team';

        this.state = {
            team: {},
            teamMembers: [],
            isPageAnimating: true,
        };
    }

    async componentDidMount() {
        const [{ data: team }, { data: teamMembers }] = await Promise.all([
            Team.getTeamData(),
            Team.getTeamMembers(),
        ]);
        this.setState({
            team,
            teamMembers,
            isPageAnimating: false,
        });
    }

    static getTeamData() {
        return axios.get(`${BASE_PATH}/pages/${TEAM_PAGE_ID}?_embed`);
    }

    static getTeamMembers() {
        return axios.get(`${BASE_PATH}/team_member?_embed`, {
            params: {
                page: 1,
                per_page: 50,
            },
        });
    }

    teamContent() {
        const { content } = this.state.team;
        return content
            ? content.rendered
            : '';
    }

    render() {
        const {
            teamMembers,
            isPageAnimating,
        } = this.state;

        return (
            <div className="page team">
                <Header />
                <div className={`pageContent ${isPageAnimating ? 'animating' : ''}`}>
                    <div className="section calloutSection">
                        <MainCallout content={this.teamContent()} />
                    </div>
                    <div className="section teamMemberSection">
                        <div className="container">
                            <h2 className="mb-4">Meet the Team</h2>
                            {Boolean(teamMembers.length) && (
                                <div className="teamMembers">
                                    {teamMembers.map((member) =>
                                        <TeamMember key={member.id} member={member} />
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        );
    }
}

export default Team;
