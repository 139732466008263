import React, { PureComponent } from 'react';

class MainCallout extends PureComponent {
    contentAsHtml() {
        return {__html: this.props.content}
    }
  
    render() {
        const { isQuoteSection } = this.props;

        return (
            <div className="container">
                <p
                    className={`${isQuoteSection ? 'fade-in-out' : ''}`}
                    dangerouslySetInnerHTML={this.contentAsHtml()}
                ></p>
            </div>
        );
    }
}

export default MainCallout;
