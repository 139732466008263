import React, { PureComponent } from 'react';
import axios from 'axios';
import { BASE_MAIL_URL } from '../util/constants';

class Contact extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            nameVal: '',
            emailVal: '',
            subjectVal: '',
            messageVal: '',
            success: false,
            errors: [],
        }

        this.successTimeout = null;
        this.nameRef = React.createRef();
        this.emailRef = React.createRef();
        this.subjectRef = React.createRef();
        this.messageRef = React.createRef();
    }

    componentWillUnmount() {
        clearTimeout(this.successTimeout);
    }

    onSubmit() {
        const errors = [];

        this.setState({
            success: false,
            errors,
        });

        const { 
            nameVal: name,
            emailVal: email,
            subjectVal: subject,
            messageVal: message,
        } = this.state;

        if (!name) {
            errors.push('The name field is empty');
        }

        if (!email) {
            errors.push('The email field is empty');
        }

        if (!subject) {
            errors.push('The subject field is empty');
        }

        if (!message) {
            errors.push('The message field is empty');
        }

        if (errors.length) {
            return;
        }

        axios({
            method: 'post',
            url: `${BASE_MAIL_URL}/mailer.php`,
            data: {
                name,
                email,
                subject,
                message,
            },
            headers: { 
                'Content-Type': 'application/json',
                'X-PING-TRIGGERMOMENTS': 'pingtrigger',
            },
            withCredentials: true,
        })
            .then((response) => {
                const { data } = response;

                if (data.status && data.status === 'fail') {
                    errors.push('There was a problem sending your email');
                    this.setState({
                        success: false,
                        errors,
                    });
                } else if (data.status && data.status === 'success') {
                    this.setState({
                        success: true,
                        errors: [],
                    });
                    this.successTimeout = setTimeout(() => {
                        this.setState({
                            success: false,
                        });
                    }, 5000);
                }
            })
            .catch((_e) => {
                errors.push('There was a problem sending your email');
                this.setState({
                    success: false,
                    errors,
                });
            });
    }

    handleNameKeyUp({ current }) {
        const { value: nameVal } = current;
        this.setState({ nameVal });
    }

    handleEmailKeyUp({ current }) {
        const { value: emailVal } = current;
        this.setState({ emailVal });
    }

    handleSubjectKeyUp({ current }) {
        const { value: subjectVal } = current;
        this.setState({ subjectVal });
    }

    handleMessageKeyUp({ current }) {
        const { value: messageVal } = current;
        this.setState({ messageVal });
    }

    render() {
        const { errors, success } = this.state;

        return (
            <div className="container">
                <h2 className="homeHeading">Get In Touch</h2>
                <p className="text-center mb-4">Whatever&apos;s next, we&apos;re here for it.</p>
                <form className="contactForm">
                    <label>
                        <input
                            ref={this.nameRef}
                            placeholder="Name"
                            name="name"
                            aria-label="name"
                            onKeyUp={this.handleNameKeyUp.bind(this, this.nameRef)}
                        />
                    </label>
                    <label>
                        <input
                            ref={this.emailRef}
                            placeholder="Email"
                            name="email"
                            aria-label="email"
                            onKeyUp={this.handleEmailKeyUp.bind(this, this.emailRef)}
                        />
                    </label>
                    <label>
                        <input
                            ref={this.subjectRef}
                            placeholder="Subject"
                            name="subject"
                            aria-label="subject"
                            onKeyUp={this.handleSubjectKeyUp.bind(this, this.subjectRef)}
                        />
                    </label>
                    <label>
                        <textarea
                            ref={this.messageRef}
                            placeholder="Message"
                            name="message"
                            aria-label="message"
                            onKeyUp={this.handleMessageKeyUp.bind(this, this.messageRef)}
                        />
                    </label>
                    <button
                        type="button"
                        className="btnSubmit"
                        onClick={() => this.onSubmit()}
                    >
                        Send
                    </button>
                </form>
                <div className="contactErrors">
                    {errors.map((error, idx) => <p key={idx}>{ error }</p>)}
                </div>
                {success && (
                    <p className="text-center mb-4">Your message has been sent!</p>
                )}
                <div className="text-center">
                    <p>Trigger(House is based in Portsmouth, NH and Brooklyn, NY</p>
                    <p>
                        <span>p. 603.427.8997</span>
                        <span>e. info@triggerhouse.com</span>
                    </p>
                </div>
            </div>
        );
    }
}

export default Contact;
