import React, { PureComponent } from 'react'; 
import { get, isEmpty, debounce } from 'lodash';

class TeamMember extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            rolloverVisible: false,
        };
        this.debouncedMouseEnter = debounce(this.toggleImages.bind(this, true), 100);
        this.debouncedMouseLeave = debounce(this.toggleImages.bind(this, false), 100);
    }

    componentWillUnmount() {
        this.debouncedMouseEnter.cancel();
        this.debouncedMouseLeave.cancel();
    }

    /**
     * Get the image url for the project
     */
    imageUrl() {
        const { member } = this.props;
        const sizes = get(member, '_embedded.wp:featuredmedia.0.media_details.sizes', {});

        if (isEmpty(sizes)) {
            return '';
        }

        if (sizes.large) {
            return sizes.large.source_url;
        }

        if (sizes.medium_large) {
            return sizes.medium_large.source_url;
        }

        return sizes.full.source_url;
    }

    name() {
        const { member } = this.props;
        return member.title ? member.title.rendered : '';
    }

    jobTitle() {
        const { member } = this.props;
        return member.meta
            ? member.meta.job_title
            : '';
    }

    rolloverImageUrl() {
        const { member } = this.props;
        return member.rollover_image
            ? member.rollover_image[0]
            : '';
    }

    toggleImages(bool) {
        this.setState({
            rolloverVisible: bool,
        });
    }

    render() {
        const { rolloverVisible } = this.state;
        const name = this.name();

        return (
            <div className="member">
                <div className="memberImagesWrap">
                    <div
                        className="memberImages"
                        onMouseEnter={this.debouncedMouseEnter}
                        onMouseLeave={this.debouncedMouseLeave}
                        onTouchStart={this.debouncedMouseEnter}
                        onTouchEnd={this.debouncedMouseLeave}
                    >
                        <img
                            src={this.imageUrl()}
                            alt={name}
                            className={`memberImg mainImg ${!rolloverVisible ? 'visible' : ''}`}
                        />
                        <img
                            src={this.rolloverImageUrl()}
                            alt={name}
                            className={`memberImg rolloverImg ${rolloverVisible ? 'visible' : ''}`}
                        />
                    </div>
                </div>
                <h6 className="memberName">{name}</h6>
                <p>{this.jobTitle()}</p>
            </div>
        );
    }
}

export default TeamMember;
