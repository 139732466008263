import React, { PureComponent } from 'react'; 
import axios from 'axios';
import { BASE_PATH, HIRING_PAGE_ID } from '../util/constants';
import Header from '../components/Header';
import Footer from '../components/Footer';

class Hiring extends PureComponent {
    constructor(props) {
        super(props);

        document.title = 'Triggerhouse | Jobs';

        this.state = {
            hiring: {},
            isPageAnimating: true,
        };
    }

    async componentDidMount() {
        const { data: hiring } = await Hiring.getHiringData();

        this.setState({
            hiring,
            isPageAnimating: false,
        });
    }

    static getHiringData() {
        return axios.get(`${BASE_PATH}/pages/${HIRING_PAGE_ID}?_embed`);
    }

    featuredImageUrl() {
        const { featured_img_url: featuredImageUrl } = this.state.hiring;
        return featuredImageUrl;
    }

    hiringContent() {
        const { content } = this.state.hiring;
        return content
            ? { __html: content.rendered }
            : { __html: ''};
    }

    render() {
        const {
            isPageAnimating,
        } = this.state;

        return (
            <div className="page page--template hiring">
                <Header />
                <div class={`section section--hiring container ${isPageAnimating ? 'animating' : ''}`}>
                    {this.featuredImageUrl() && (
                        <div className="heroImgWrap">
                            <img
                                src={this.featuredImageUrl()}
                                alt=""
                            />
                        </div>
                    )}
                </div>
                <div className={`pageContent ${isPageAnimating ? 'animating' : ''}`}>
                    <div className="section container" dangerouslySetInnerHTML={this.hiringContent()} />
                </div>
                <Footer />
            </div>
        );
    }
}

export default Hiring;
