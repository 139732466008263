import React, { PureComponent } from 'react'; 
import axios from 'axios';
import { BASE_PATH, WORK_PAGE_ID } from '../util/constants';
import Header from '../components/Header';
import Footer from '../components/Footer';
import MainCallout from '../components/MainCallout';
import CategoryFilters from '../components/CategoryFilters';
import Projects from '../components/Projects';

class Work extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            categories: [],
            projects: [],
            page: {},
            activeProjects: [],
            selectedCategoryId: '',
            isPageAnimating: true,
        };
    }

    async componentDidMount() {
        const [{ data: categories }, { data: projects }, { data: page }] = await Promise.all([
            await Work.getCategories(),
            await Work.getProjects(),
            await Work.getPage(),
        ]);

        this.setState({ 
            categories,
            projects,
            page,
            isAnimating: true,
            isPageAnimating: false,
        });
        this.fader = setTimeout(() => { this.setState({ isAnimating: false })}, 500);
    }

    static getProjects() {
        return axios.get(`${BASE_PATH}/project?_embed`, {
            params: {
                page: 1,
                per_page: 100,
                meta_key: 'feature_on_work_page',
                meta_value: 1,
            },
        });
    }

    static getCategories() {
        return axios.get(`${BASE_PATH}/project_categories?_embed`);
    }

    static getPage() {
        return axios.get(`${BASE_PATH}/pages/${WORK_PAGE_ID}`);
    }

    componentWillUnmount() {
        clearTimeout(this.fader);
    }

    setSelectedCategory(categoryId) {
        const selectedCategoryId = categoryId.toString();
        this.setState({
            selectedCategoryId,
            isAnimating: true,
        }, () => this.setActiveProjects());
        this.fader = setTimeout(() => { this.setState({ isAnimating: false })}, 500);
    }

    setActiveProjects() {
        const { projects, selectedCategoryId } = this.state;

        const activeProjects = projects.reduce((acc = [], project) => {
            const { project_categories: projectCategories } = project;
            const hasCategory = projectCategories.some(categoryId => categoryId.toString() === selectedCategoryId);

            if (hasCategory) {
                acc.push(project);
            }
            return acc;
        }, []);

        this.setState({ activeProjects });
    }

    pageContent() {
        const { content } = this.state.page;

        if (!content) {
            return '';
        }

        return content.rendered || '';
    }

    projectsToShow() {
        const { projects, activeProjects } = this.state;

        return activeProjects && Boolean(activeProjects.length)
            ? activeProjects
            : projects;
    }

    render() {
        const {
            categories,
            projects,
            isAnimating,
            isPageAnimating,
        } = this.state;

        return (
            <div className="page work">
                <Header />
                <div className={`pageContent ${isPageAnimating ? 'animating' : ''}`}>
                    <div className="section calloutSection">
                        <MainCallout content={this.pageContent()} />
                    </div>

                    <div className="section filtersSection">
                        <div className="container">
                            {Boolean(categories.length) && (
                                <div>
                                    <h4 className="text-center">Filter by Category</h4>
                                    <CategoryFilters
                                        categories={categories}
                                        setCategory={this.setSelectedCategory.bind(this)}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                    
                    <div className={`section projectsSection ${isAnimating ? 'animating' : ''}`}>
                        {Boolean(projects.length) && (
                            <Projects projects={this.projectsToShow()} />
                        )}
                    </div>
                </div>
                <Footer />
            </div>
        );
    }

}

export default Work;
