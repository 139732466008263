import { isEmpty } from 'lodash';
import React, { Component } from 'react';

class ProjectHero extends Component {
    project() {
        return this.props.project || {};
    }

    /**
     * Get the image url for the project
     */
    imageUrl() {
        const project = this.project();

        if (isEmpty(project)) {
            return '';
        }

        return project.featured_img_url;
    }

    render() {
        return (
            <div className="detailImgWrap">
                <img
                    className="detailHeroImg"
                    src={this.imageUrl()}
                    alt=""
                />
            </div>
        );
    }
}

export default ProjectHero;
